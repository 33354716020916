/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Title, YouTube } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Gallery"}>
        <SiteHeader set="" currentLanguage={1} />

        <Column className="pb--60 pt--60" layout={"l8"} name={"rada0zb4x4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/6d42b21bddda4e6eae99e8c2e3746c54_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/6d42b21bddda4e6eae99e8c2e3746c54_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/6d42b21bddda4e6eae99e8c2e3746c54_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/6d42b21bddda4e6eae99e8c2e3746c54_s=860x_.png 860w"} lightbox={false} use={"url"} href={"https://vimeo.com/986577747/f8ed1663e2"} url={"https://vimeo.com/986577747/f8ed1663e2"}>
              </Image>

              <Text className="text-box" content={"wedding clip - video"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/53185c1ba361420c9e211eedb50fa4de_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/53185c1ba361420c9e211eedb50fa4de_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/53185c1ba361420c9e211eedb50fa4de_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/53185c1ba361420c9e211eedb50fa4de_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://vimeo.com/989453811/ffcd219434"} url={"https://vimeo.com/989453811/ffcd219434"}>
              </Image>

              <Text className="text-box" content={"wedding ceremony - video"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/321fd08daaf14e59a7d1e819b7611a66_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/321fd08daaf14e59a7d1e819b7611a66_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/321fd08daaf14e59a7d1e819b7611a66_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/321fd08daaf14e59a7d1e819b7611a66_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://drive.google.com/drive/folders/1paghQOT1fGiIMEU6bOmMlgFFkieqNOy1"} url={"https://drive.google.com/drive/folders/1paghQOT1fGiIMEU6bOmMlgFFkieqNOy1"}>
              </Image>

              <Text className="text-box" content={"wedding photos to download&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"font-style: italic;\">click on the image to play the video or go to the photo download link</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--80" name={"tabwas9ah5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--color-blend--50);\">VIDEOS TO WATCH</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"je9e2gb0jjp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <YouTube embedId={"oqqX8GwYsM4"} modestbranding={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cez0y9qa4y9"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <YouTube embedId={"17jqL9PtZk4"} modestbranding={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"z8kfdojd8k"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <YouTube embedId={"P8HGJBMhKTY"} modestbranding={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6o3ob1dho0t"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <YouTube embedId={"d_Kf8fxrrQ4"} modestbranding={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}